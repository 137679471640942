import axios from 'axios';

export const fetchIPDetails = async (ip) => {
  try {
    // Ambil token dari backend
    const { data } = await axios.get('/api/generate-token');
    const token = data.token;

    // Kirim permintaan dengan token
    const response = await axios.get('/api/ip-checker', {
      params: { ip },
      headers: {
        'x-auth-token': token,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching IP details:', error);
    throw new Error('Failed to fetch IP details. Please try again.');
  }
};