<template>
  <div id="app">
    <!-- Header -->
    <header class="bg-white shadow-sm">
      <div class="container mx-auto px-4 py-4 flex justify-between items-center">
        <div class="flex items-center">
          <span class="ml-2 text-xl font-semibold">Algocore</span>
        </div>
      </div>
    </header>

    <!-- Main Content -->
    <main class="container mx-auto px-4 py-16 text-center">
      <h1 class="text-3xl font-bold mb-4">Quality IP Checker</h1>
      <p class="text-gray-600 mb-8">
        Enter an IP address to analyze detailed information about it.
      </p>
      <div class="max-w-md mx-auto">
        <input
          v-model="ipAddress"
          class="w-full p-3 border border-gray-300 rounded mb-4"
          placeholder="Enter IP address"
          type="text"
        />
        <button
          @click="checkIP"
          class="w-full p-3 bg-green-500 text-white rounded"
        >
          Check IP
        </button>
        <p v-if="errorMessage" class="text-red-500 mt-4">{{ errorMessage }}</p>
      </div>

      <!-- Results Section -->
      <div
        v-if="result"
        class="mt-8 bg-white shadow-md rounded-lg overflow-hidden"
      >
        <div class="bg-gray-100 p-4 text-left font-semibold">
          IP Address Results
        </div>
        <div class="overflow-x-auto">
          <table v-if="result" class="w-full text-left">
            <tbody>
              <tr v-for="(value, key) in result" :key="key">
                <td class="p-4 border-t">{{ formatKey(key) }}</td>
                <td class="p-4 border-t">{{ value }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </main>

    <!-- Footer -->
    <footer class="bg-gray-100 py-4">
      <div class="container mx-auto px-4 flex justify-between items-center">
        <p class="text-gray-600">© {{ currentYear }} Algocore. All rights reserved.</p>
        <div class="space-x-4">
          <a href="#" class="text-gray-600 hover:text-gray-800"><i class="fab fa-facebook-f"></i></a>
          <a href="#" class="text-gray-600 hover:text-gray-800"><i class="fab fa-twitter"></i></a>
          <a href="#" class="text-gray-600 hover:text-gray-800"><i class="fab fa-linkedin-in"></i></a>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import { fetchIPDetails } from "./services/api";

export default {
  data() {
    return {
      ipAddress: "",
      result: null,
      currentYear: new Date().getFullYear(), // Tahun saat ini
      errorMessage: null, // Untuk pesan error
    };
  },
  methods: {
    async checkIP() {
      // Validasi IP Address menggunakan regex
      const ipRegex =
        /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;

      if (!this.ipAddress || !ipRegex.test(this.ipAddress)) {
        this.errorMessage = "Please enter a valid IP address.";
        return;
      }

      // Reset pesan error sebelum mengirim permintaan
      this.errorMessage = null;

      try {
        this.result = await fetchIPDetails(this.ipAddress);
      } catch (error) {
        this.errorMessage = "Failed to fetch IP details. Please try again.";
      }
    },
    formatKey(key) {
      if (typeof key !== "string") {
        return String(key); // Konversi ke string jika memungkinkan
      }
      console.log("Key received in formatKey:", key);
      return key
        .replace(/_/g, " ") // Ganti underscore dengan spasi
        .replace(/\b\w/g, (char) => char.toUpperCase()); // Huruf pertama tiap kata kapital
    },
  },
};
</script>

<style>
/* Sticky Footer */
body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  margin: 0;
}

#app {
  flex: 1;
  display: flex;
  flex-direction: column;
}

main {
  flex: 1;
}
</style>